
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { FloatLabel } from 'primereact/floatlabel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColorPicker } from 'primereact/colorpicker';

const AddGradeMasterDialog = (props) => {
    const [gradeTemplateName, setGradeTemplateName] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [gradeTemplateNameErrText, setGradeTemplateNameErrText] = useState('');
    const [gradeTemplateNameErr, setGradeTemplateNameErr] = useState(false);
    const [gradeTemplateValues, setGradeTemplateValues] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        let values = [
            {
                'GradeName': '',
                'PercentageFrom': '',
                'PercentageTo': '',
                'HexCode': '',
                'Remark': ''
            }
        ]
        if (props.ID) {
            setGradeTemplateName(props?.masterData?.GradeTemplateName);
            let valuesData = props?.masterData?.GradeTemplateValues ? props?.masterData?.GradeTemplateValues : values;
            setGradeTemplateValues(valuesData)
        } else {
            setGradeTemplateValues(values);
        }
    }, []);

    const onCancel = () => {
        props.onCancel();
        setGradeTemplateName('');
        setGradeTemplateValues([]);
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(gradeTemplateName)) {
            formIsValid = false;
            setGradeTemplateNameErr(true);
            setGradeTemplateNameErrText("Grade Template Name is required");
        } else {
            setGradeTemplateNameErr(false);
            setGradeTemplateNameErrText("");
        }
        return formIsValid;
    };

    const AddGradeMaster = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props?.ID,
                    GradeTemplateName: gradeTemplateName,
                    GradeTemplateValues: gradeTemplateValues ? gradeTemplateValues.filter(x => !CommonConfig.isEmpty(x?.GradeName)) : [],
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateGradeMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setGradeTemplateName(alphanumericValue);
    };

    const gradeNameBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <InputText
                    className="p-mt-2 p-w-50"
                    id={`gradeName-${rowIndex}`}
                    type="text"
                    maxLength={20}
                    placeholder="Grade Name"
                    value={rowData?.GradeName}
                    onChange={(e) => handleTemplateValuesChange(rowIndex, 'GradeName', e.target.value)}
                />
            </div>
        )
    };

    const percentageFromBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <InputText
                    className="p-mt-2 p-w-20"
                    id={`percentageFrom-${rowIndex}`}
                    type="text"
                    maxLength={3}
                    placeholder="Percentage From"
                    value={rowData?.PercentageFrom}
                    onChange={(e) => handleTemplateValuesChange(rowIndex, 'PercentageFrom', e.target.value)}
                />
                <InputText
                    className="p-mt-2 p-w-20"
                    id={`percentageTo-${rowIndex}`}
                    type="text"
                    maxLength={3}
                    placeholder="Percentage To"
                    value={rowData?.PercentageTo}
                    onChange={(e) => handleTemplateValuesChange(rowIndex, 'PercentageTo', e.target.value)}
                />
            </div>
        )
    };

    const hexCodeBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <ColorPicker
                    value={rowData?.HexCode}
                    onChange={(e) => handleTemplateValuesChange(rowIndex, 'HexCode', e.value)}
                />
                <InputText
                    className="p-mt-2 p-w-80"
                    id={`hexCode-${rowIndex}`}
                    type="text"
                    placeholder="Hex Code"
                    disabled={true}
                    value={rowData?.HexCode?.toUpperCase()}
                />
            </div>
        )
    };

    const remarkBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <InputText
                    className="p-mt-2 p-w-80"
                    id={`remark-${rowIndex}`}
                    type="text"
                    maxLength={20}
                    placeholder="Remark"
                    value={rowData?.Remark}
                    onChange={(e) => handleTemplateValuesChange(rowIndex, 'Remark', e.target.value)}
                />
            </div>
        )
    };

    const actionsBodyTemplate = (rowData, { rowIndex }) => (
        <div className="p-d-flex p-jc-start">
            {rowIndex === gradeTemplateValues.length - 1 &&
                <Button
                    icon="pi pi-plus"
                    className="e-sction-btn p-m-0 p-m-2"
                    onClick={() => addTemplateValue()}
                />}
            {(rowIndex !== 0 || gradeTemplateValues.length > 1) &&
                <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2"
                    onClick={() => removeTemplateValue(rowIndex)}
                />
            }
        </div>
    );

    const addTemplateValue = () => {
        let values = [{
            'GradeName': '',
            'PercentageFrom': '',
            'PercentageTo': '',
            'HexCode': '',
            'Remark': ''
        }];
        setGradeTemplateValues([...gradeTemplateValues, ...values]);
    };

    const removeTemplateValue = (index) => {
        const updatedTemplates = gradeTemplateValues?.filter((_, i) => i !== index);
        setGradeTemplateValues(updatedTemplates);
    };

    const handleTemplateValuesChange = (index, key, value) => {
        const updatedTemplateValues = [...gradeTemplateValues];
        if (key === 'PercentageFrom' || key === 'PercentageTo') {
            const numericValue = value.replace(/[^0-9]/g, '');
            updatedTemplateValues[index][key] = numericValue;
        } else {
            const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*]/g, '');
            updatedTemplateValues[index][key] = alphanumericValue;
        }
        setGradeTemplateValues(updatedTemplateValues);
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-12 p-pt-2">
                    <div className="">
                        <label className='' htmlFor="gradeTemplateName">Grade Template Name</label>
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-w-100"
                                id="gradeTemplateName"
                                type="text"
                                maxLength={20}
                                placeholder="Grade Template Name"
                                value={gradeTemplateName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e)}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {gradeTemplateNameErr ? gradeTemplateNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className='p-mt-3'>
                    <DataTable value={gradeTemplateValues} responsiveLayout="scroll">
                        <Column header="Grade Name" body={gradeNameBodyTemplate} />
                        <Column header="Percentage" body={percentageFromBodyTemplate} />
                        <Column header="Hex Code" body={hexCodeBodyTemplate} />
                        <Column header="Remark" body={remarkBodyTemplate} />
                        <Column header="Actions" body={actionsBodyTemplate} />
                    </DataTable>
                </div>
            </div>
            <div className='dialog-footer1 p-d-flex p-ai-center p-jc-end'>
                <Button className='p-m-0 primary-btn1 p-m-1' onClick={(e) => onCancel()}> Cancel </Button>
                <Button className='p-m-0 primary-btn' label="Submit" severity="secondary" raised onClick={(e) => AddGradeMaster()} />
            </div>
        </div>
    );
};

export default AddGradeMasterDialog;
