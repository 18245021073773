import React from 'react';
import { apiBase } from '../../utils/config';
import moment from 'moment';
import UserImage from '../../assets/icons/OBJECTS.svg';

const ExportIdCardPDF = React.forwardRef((props, ref) => {
    const { templateConfiguration, pdfData } = props;

    const gradientStyle = (colorCode) => {
        const fallbackColor = '#649A80';
        const isValidHex = (code) => /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(code);
        const validColor = isValidHex(colorCode) ? (colorCode.startsWith('#') ? colorCode : `#${colorCode}`) : fallbackColor;
        return {
            background: `linear-gradient(to bottom, #E2E379, #A6B974, ${validColor})`,
            borderRadius: '4px',
            padding: '10px',
            // width: templateConfiguration?.TemplateWidth ? (((templateConfiguration?.TemplateWidth) / 2.54) * 96) + 22 : '200px',
        };
    };

    return <div style={{ flex: '0 1 calc(20% - 8px)', marginLeft: '3px', marginTop: '15px', width: '180px', height: '340px', justifyContent: 'center', ...gradientStyle(pdfData?.HouseColor) }} ref={ref}>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '270px' }}>
            <div style={{ position: 'relative', backgroundColor: 'transparent', borderRadius: '6px', opacity: 1, marginTop: '3px' }}>
                <div style={{ width: '100%', maxHeight: '270px', height: '45px', backgroundColor: '#e2e379', display: 'flex', marginTop: '3px' }}>
                    <img
                        src={templateConfiguration?.SchoolImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SchoolImage}` : pdfData?.SchoolPhotoUrl ? `${apiBase}/UserProfilePic/${pdfData?.SchoolPhotoUrl}` : UserImage}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }}
                    />
                </div>
                <div style={{ position: 'relative', height: 250, backgroundColor: '#FFFFFF', borderRadius: '6px', opacity: '1', marginTop: '10px', }}>
                    <div style={{ backgroundColor: 'transparent', borderRadius: '50%', position: 'absolute', right: '0%', opacity: 0.06, }}>
                        <img style={{ width: '11rem', height: '11rem', borderRadius: '50%' }} src={templateConfiguration?.IsHouseWiseImage ? `${apiBase}/TemplateBackGround/${pdfData?.HouseBgImage}` : templateConfiguration?.BackGroundImage ? `${apiBase}/TemplateBackGround/${templateConfiguration?.BackGroundImage}` : UserImage}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = UserImage;
                            }} />
                    </div>
                    {templateConfiguration?.BodySection?.map((y, index) => {
                        return (
                            <div style={{ marginTop: '3px' }}>
                                {y?.key === 'AcademicYear' ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0.3rem' }}>
                                        <p style={{ margin: '0px', fontSize: '0.65rem', fontStyle: 'normal', fontWeight: 700, color: '#074E69' }}>{pdfData?.AcademicYear ?? '-'}</p>
                                    </div> :
                                    y?.key === 'StudentProfilePic' ?
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{
                                                borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px', width: '50px', height: '50px'
                                            }}
                                            >
                                                <img
                                                    src={pdfData?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${pdfData?.ProfilePhotoUrl}` : UserImage}
                                                    style={{
                                                        borderRadius: templateConfiguration?.ProfileStyle === 'round' ? '50%' : '6px', width: '50px', height: '50px', backgroundColor: '#FFFFFF', margin: '0.3rem auto 0.3rem auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                    }}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = UserImage;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        : y?.key === 'Class&Section' ?
                                            <p style={{ margin: '0px', fontSize: '0.60rem', fontStyle: 'normal', fontWeight: 600, color: '#064D69', position: 'absolute', top: '18%', right: '6%', transform: `rotate(${templateConfiguration?.ClassAngle}deg)`, }}>{pdfData?.ClassName}</p>
                                            : y?.key === 'StudentName' ?
                                                <div style={{
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.9, height: '25px', marginTop: '10px', backgroundColor: templateConfiguration?.NameBGColor ? `#${templateConfiguration?.NameBGColor}` : pdfData?.HouseColor ? `#${pdfData?.HouseColor}` : '#064D69', textAlign: 'center'
                                                }}
                                                >
                                                    <p style={{
                                                        color: templateConfiguration?.NameColor ? `#${templateConfiguration?.NameColor}` : '#FFFFFF', margin: '0px', fontSize: '0.7rem'
                                                    }}>{pdfData?.Name}</p>
                                                </div>
                                                : (y?.key === 'contactNo' || y?.key === 'schoolEmail') ?
                                                    null :
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'baseline',
                                                            marginBottom: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <p style={{
                                                            margin: 0,
                                                            fontSize: '0.55rem',
                                                            fontWeight: 700,
                                                            color: '#064D69',
                                                            textTransform: 'capitalize',
                                                            maxWidth: '80px',
                                                            flex: '1',
                                                            textOverflow: 'ellipsis',
                                                            // whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'normal',
                                                            wordWrap: 'break-word',
                                                            marginLeft: '10px',
                                                        }}
                                                        >
                                                            {y?.label}
                                                        </p>
                                                        <p style={{
                                                            margin: 0,
                                                            fontSize: y?.key === 'Address' ? '0.40rem' : '0.55rem',
                                                            fontWeight: 400,
                                                            color: '#064D69',
                                                            textTransform: 'capitalize',
                                                            // maxWidth: '150px',
                                                            // display: 'flex',
                                                            // alignItems: 'center',
                                                            // flex: '1',
                                                            // justifyContent: 'flex-start',
                                                            // textOverflow: 'ellipsis',
                                                            // whiteSpace: 'normal',
                                                            // wordWrap: 'break-word',
                                                            // overflowWrap: 'break-word',
                                                            // wordBreak: 'break-word',
                                                            display: "-webkit-box",
                                                            maxWidth: 78,
                                                            width: 78,
                                                            WebkitLineClamp: "3",
                                                            WebkitBoxOrient: "vertical",
                                                            overflow: "hidden"
                                                        }}
                                                        >
                                                            {y?.key === 'DOB' ? (pdfData?.[y?.key] ? moment(pdfData?.[y?.key]).format("DD-MM-YYYY") : '') : pdfData?.[y?.key]}
                                                        </p>
                                                    </div>

                                }
                            </div>
                        )
                    }
                    )}

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', paddingBottom: '0.5rem' }}>
                        <img src={templateConfiguration?.SignImage ? `${apiBase}/TemplateSign/${templateConfiguration?.SignImage}` : UserImage} style={{ width: '20px', height: '20px', objectFit: 'contain', marginRight: '10px' }} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = UserImage;
                        }} />
                        <p className="p-w-md-auto p-mr-2" style={{ margin: '0px', fontSize: '0.5rem', fontStyle: 'normal', fontWeight: 700, color: '#064D69', textTransform: 'capitalize', maxWidth: '70px', width: '70', }}>{templateConfiguration?.AuthorityTitle}</p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ fontSize: '0.50rem', fontStyle: 'normal', fontWeight: 500, color: '#FFFFFF', marginTop: '4px', marginBottom: '0px' }}>
                    {templateConfiguration?.BodySection?.filter(x => x?.key === 'contactNo').length ? pdfData?.SchoolPhone : ''} , {templateConfiguration?.BodySection?.filter(x => x?.key === 'schoolEmail').length ? pdfData?.SchoolEmail : ''}
                </p>
            </div>
        </div>
    </div>
});

export default ExportIdCardPDF;
