import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/apiClient';
import { PanelMenu } from 'primereact/panelmenu';
import CommonConfig from '../../utils/constant';
import APIConstant from '../../utils/pathConstants';
import logo from '../../assets/icons/OBJECTS.svg';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';          
import 'primeicons/primeicons.css';                       

const Sidebar = (props) => {
  const [sidebarMenus, setSidebarMenus] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = CommonConfig.loginData();
    getUserMenu();
  }, []);

  const getUserMenu = async () => {
    try {
      const user = CommonConfig.loginData();
      const data = { UserId: user?._id, RoleId: user?.UserType };
      const res = await api.post(APIConstant.path.getUserMenu, data);
      if (res.success) {
        if (res.data.length) {
          setSidebarMenus(transformMenuData(res.data));
        } else {
          navigate('/edit-profile');
        }
      } else {
        setSidebarMenus([]);
      }
    } catch (err) {
      console.error('Error fetching user menu:', err);
    }
  };

  const manageSidebarWidth = () => {
    document.getElementById('sidebar-area').classList.toggle('sidebar-mini');
  };

  const handleMenuClick = (x, idx) => {
    if (x.Path) {
      navigate(x.Path);
    } else {
      let tempSidebarMenu = [...sidebarMenus];
      tempSidebarMenu[idx]["IsCollapseActive"] =
        tempSidebarMenu[idx].Children.filter((x) => x.IsCollapseActive == true)
          .length && tempSidebarMenu[idx]["IsCollapseActive"] == true
          ? tempSidebarMenu[idx]["IsCollapseActive"]
          : !tempSidebarMenu[idx].Children.filter(
            (x) => x.IsCollapseActive == true
          ).length && tempSidebarMenu[idx]["IsCollapseActive"] == true
            ? !tempSidebarMenu[idx]["IsCollapseActive"]
            : !tempSidebarMenu[idx]["IsCollapseActive"];

      setSidebarMenus([...tempSidebarMenu]);
    }
  };

  const transformMenuData = (data) => {
    return data.map((menu) => {
      const transformedMenu = {
        label: menu.Title,
        icon: menu.Icon || 'pi pi-fw pi-info-circle',
        command: () => menu.Path && navigate(menu.Path),
      };

      if (menu.Children && menu.Children.length > 0) {
        transformedMenu.items = menu.Children.map((child) => {
          const transformedChild = {
            label: child.Title,
            icon: child.Icon || 'pi pi-fw pi-folder',
            command: () => child.Path && navigate(child.Path),
          };

          if (child.ChildSubMenus && child.ChildSubMenus.length > 0) {
            transformedChild.items = child.ChildSubMenus.map((subChild) => ({
              label: subChild.Title,
              icon: subChild.Icon || 'pi pi-fw pi-file',
              command: () => subChild.Path && navigate(subChild.Path),
            }));
          }

          return transformedChild;
        });
      }

      return transformedMenu;
    });
  };


  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`sidebar-container ${isExpanded ? 'expanded' : 'collapsed'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-header">
        <div className='sidebar-header2'>
          <div className="sidebar-logo">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
      <div className="sidebar-body">
        {sidebarMenus.length > 0 ? (
          <PanelMenu model={sidebarMenus} className="p-m-2" />
        ) : (
          <p>No menus available</p>
        )}
      </div>
      <div className="sidebar-footer">
        {/* <Button
          label="Need Help?"
          icon="pi pi-question-circle"
          className="p-button-text p-d-block p-mx-auto"
        /> */}
        Need help? Please contact us.
      </div>
    </div>
  );
};

export default Sidebar;

