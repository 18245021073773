import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import CommonConfig from '../../../utils/constant';
import Loader from "../../Shared/Loader/Loader";
import Toast from '../../Shared/Toast/Toast';
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';

const StudentList = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [visible, setVisible] = useState(false);
    const [studentsList, setStudentsList] = useState([]);
    const [selectedHouse, setSelectedHouse] = useState([]);
    const [isRandom, setIsRandom] = useState(false);
    const [orderByValue, setOrderByValue] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [houseList, setHouseList] = useState([]);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        setStudentsList(props?.studentsList);
        setSelectedStudents(props?.studentsList);
        GetHouseList(loginData?._id);
    }, []);

    const resetForm = () => {
        setSelectedHouse([]);
        setIsRandom(false);
    };

    const onCancel = () => {
        setVisible(false);
        resetForm();
    };

    const GetHouseList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getHouseList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setHouseList(res.data.map(x => ({
                        ...x,
                        label: x?.HouseName,
                        value: x._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const assignhouse = async (e) => {
        if (selectedHouse.length) {
            try {
                Loader.show();
                for (let student of selectedStudents) {
                    let houseValue;

                    if (selectedHouse.length === 1) {
                        houseValue = selectedHouse[0];
                    } else {
                        if (student?.Gender === 'male') {
                            houseValue = selectedHouse[Math.floor(Math.random() * Math.ceil(selectedHouse.length / 2))];
                        } else if (student?.Gender === 'female') {
                            houseValue = selectedHouse[Math.floor(Math.random() * Math.ceil(selectedHouse.length / 2)) + Math.floor(selectedHouse.length / 2)];
                        } else {
                            houseValue = selectedHouse[Math.floor(Math.random() * selectedHouse.length)];
                        }
                    }
                    let data = {
                        id: student?.AdmissionId,
                        UserId: student?.id,
                        House: houseValue,
                    };
                    await api
                        .post(APIConstant.path.AddAdmissionDetail, data)
                        .then((response) => {
                            let res = response;
                            if (res.success) {
                                Toast.success({ message: res.message });
                            } else {
                                Toast.error({ message: res.message });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            Toast.error({ message: 'Error assigning house' });
                        });
                }

                Loader.hide();
                onCancel();
            } catch (err) {
                console.log(err);
                Loader.hide();
                Toast.error({ message: 'Error during house assignment' });
            }
        } else {
            Toast.error({ message: 'Please select house' });
        }
    };

    const saveStudentRollNo = async (e) => {
        try {
            Loader.show();
            for (let student of studentsList) {
                let data = {
                    id: student?.AdmissionId,
                    UserId: student?.id,
                    RollNo: student?.RollNo,
                    House: student?.HouseId
                };
                await api
                    .post(APIConstant.path.AddAdmissionDetail, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                        } else {
                            Toast.error({ message: res.message });
                        }
                        props.onCancel();
                    })
                    .catch((err) => {
                        console.log(err);
                        Toast.error({ message: 'Error update rollNo' });
                    });
            }

            Loader.hide();
            onCancel();
        } catch (err) {
            console.log(err);
            Loader.hide();
            Toast.error({ message: 'Error during update rollNo' });
        }
    };

    const handleRowReorder = (event) => {
        const updatedValues = event.value.map((item, index) => ({
            ...item,
            RollNo: index + 1,
        }));
        setStudentsList([...updatedValues]);
    };

    const handleHouseChange = (studentId, newHouse) => {
        setStudentsList((prevList) =>
            prevList.map((student) =>
                student?.id === studentId
                    ? { ...student, HouseId: newHouse }
                    : student
            )
        );
    };


    return (
        <div className="p-fluid p-grid p-dir-col">
            <div className="p-col">
                <Button label="Assign" icon="pi pi-plus" className="p-button-primary p-ml-auto" onClick={() => setVisible(true)} />
            </div>
            <DataTable value={studentsList} responsiveLayout="scroll" reorderableRows onRowReorder={handleRowReorder} selection={selectedStudents}
                onSelectionChange={(e) => setSelectedStudents(e.value)}
                selectionMode="multiple">
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column rowReorder header="#" />
                <Column field="SrNo" body={(rowData, options) => options.rowIndex + 1} header="Sr No." />
                <Column field="StudentId" header="Student ID" />
                <Column field="Name" header="Student Name" />
                <Column field="Email" header="Email" />
                <Column field="Gender" header="Gender" />
                <Column header="House" body={(rowData) => (
                    <Dropdown
                        id="houseDropdown"
                        value={rowData?.HouseId}
                        options={houseList}
                        onChange={(e) => handleHouseChange(rowData?.id, e.target.value)}
                        placeholder="Select a House"
                        className="mr-2"
                        display="chip"
                    />
                )} />
                <Column field="RollNo" header="Roll No." />
            </DataTable>
            <div className="p-d-flex p-ai-center">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={(e) => props.onCancel()} />
                <Button label="Save" className="p-button-primary p-mr-2" onClick={(e) => saveStudentRollNo()} />
            </div>

            <Dialog
                header="Assign"
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="top"
            >
                <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <h3>House</h3>
                    <div className="p-field">
                        <label htmlFor="houseDropdown">Select House</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '8px' }}>
                            <MultiSelect
                                id="houseDropdown"
                                value={selectedHouse}
                                options={houseList}
                                onChange={(e) => setSelectedHouse(e.value)}
                                placeholder="Select a House"
                                className="mr-2"
                                filter
                                display="chip"
                            />
                            <Checkbox
                                inputId="randomCheckbox"
                                checked={isRandom}
                                onChange={(e) => setIsRandom(e.checked)}
                            />
                            <label htmlFor="randomCheckbox" style={{ marginLeft: '8px' }}>Random</label>
                        </div>
                    </div>

                    <h3>Roll No.</h3>
                    <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <RadioButton
                            inputId="ByNameWise"
                            name="ByNameWise"
                            value="ByNameWise"
                            checked={orderByValue === 'ByNameWise'}
                            onChange={(e) => setOrderByValue(e.value)}
                        />
                        <label htmlFor="ByNameWise"> ByNameWise</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <RadioButton
                            inputId="ByStudentID"
                            name="byStudentID"
                            value="ByStudentID"
                            checked={orderByValue === 'ByStudentID'}
                            onChange={(e) => setOrderByValue(e.value)}
                        />
                        <label htmlFor="ByStudentID"> ByStudentID</label>
                    </div>
                </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            label="RESET"
                            className="p-button-secondary"
                            onClick={resetForm}
                            style={{ backgroundColor: '#f0f0f0', color: '#000' }}
                        />
                        <Button
                            label="ASSIGN"
                            className="p-button-primary"
                            onClick={(e) => assignhouse()}
                        />
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default StudentList;
