import React, { useState, useEffect } from 'react';
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Toast from '../Shared/Toast/Toast';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';


const TakeAttendance = () => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [classList, setClassList] = useState([]);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date);
    const [attendanceList, setAttendanceList] = useState([]);
    const attendanceOptions = [
        { label: "P", value: "P", color: "green" },
        { label: "A", value: "A", color: "red" },
        { label: "L", value: "L", color: "yellow" },
    ];
    const [isStudentView, setIsStudentView] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
    }, []);


    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            Loader.show();
            await api.post(APIConstant.path.GetAttendanceClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetAttendanceList = async (id, classId, type) => {
        try {
            let data = {
                UserID: id,
                ClassId: classId,
                SelectedDate: selectedDate,
                Type: type
            }
            Loader.show();
            await api.post(APIConstant.path.GetAttendanceListByClass, data).then(async response => {
                let res = response;
                if (res.success) {
                    setAttendanceList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const handleEdit = (rowIndex, value) => {
        setAttendanceList((prevItems) =>
            prevItems.map((item, index) =>
                index === rowIndex
                    ? { ...item, AttendanceType: value }
                    : { ...item }
            )
        );
    };

    const attendanceBodyTemplate = (rows, props) => {
        return (
            <div className="p-d-flex p-ai-center" style={{ gap: "5px" }}>
                {attendanceOptions.map((option, index) => (
                    <div key={index} className="p-d-flex p-ai-center" style={{ gap: "5px" }}>
                        <RadioButton
                            inputId={`attendance-${props.rowIndex}-${option.value}`}
                            name={`attendance-${props.rowIndex}`}
                            value={option.value}
                            checked={rows?.AttendanceType === option.value}
                            onChange={() => handleEdit(props.rowIndex, option.value)}
                        />
                        <label
                            htmlFor={`attendance-${props.rowIndex}-${option.value}`}
                            style={{
                                color: option.color,
                                fontWeight: "bold",
                                cursor: "pointer",
                            }}
                        >
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button label={rows?.IsTake ? 'Take' : 'Update'} className="e-sction-btn p-my-0 p-m-0" onClick={() => {
                        GetAttendanceList(securityUserID, rows?.ClassId, 'Today');
                        setIsStudentView(true)
                    }} />
                </div>
            </React.Fragment>

        );
    };

    const saveAttendance = async () => {
        try {
            const requestData = attendanceList?.map(row => ({
                id: row?.id,
                ClassId: row?.ClassId,
                StudentId: row?.StudentId,
                SubjectId: row?.SubjectId,
                AttendanceDate: selectedDate,
                TakenBy: securityUserID,
                AttendanceType: row?.AttendanceType,
                CreatedBy: securityUserID
            }));
            const response = await api.post(APIConstant.path.AddAttendanceDetail, requestData);
            if (response.success) {
                Toast.success({ message: response.message });
                setIsStudentView(false);
                setAttendanceList([]);
                GetClassList(securityUserID);
            } else {
                setIsStudentView(false);
                setAttendanceList([]);
                GetClassList(securityUserID);
                Toast.error({ message: "Failed to save timetable" });
            }
        } catch (error) {
            setIsStudentView(false);
            setAttendanceList([]);
            console.error("Error saving timetable:", error);
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={() => setIsStudentView(false)}
            />
            <Button
                label="Update"
                className="p-button-rounded p-button-success p-button-outlined"
                onClick={() => saveAttendance()}
                tooltip="Save Changes"
                tooltipOptions={{ position: "top" }}
            />
        </div>
    );

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-clock"></i>
                    <h2>Take Attendance</h2>
                </div>
            </div>
            <hr className='p-m-0' />
            <div className="p-grid p-align-center" style={{ gap: "10px" }}>
                <label htmlFor="attendanceDate">Attendance Date </label>
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <Calendar
                        className='p-w-100 p-mt-0'
                        id="attendanceDate" 
                        disabled
                        value={selectedDate}
                        hourFormat="12"
                        dateFormat="dd/mm/yy"
                    />
                </div>
            </div>
            <hr className='p-m-0' />
            <div>
                <hr className='p-m-0' />
                <DataTable
                    value={classList}
                    paginator
                    rows={rows}
                    first={first}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 20]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    onPage={onPageChange}
                    className='p-mt-3'
                >
                    <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                    <Column
                        field="ClassName"
                        header="Class Name"
                    />
                    <Column
                        field='ClassTime'
                        header='Class Time'
                    />
                    <Column
                        field='AttendanceData'
                        header='Total Attendance'
                    />
                    <Column
                        header="Action"
                        body={actionBodyTemplate}
                    />
                </DataTable>
            </div>
            <Dialog
                header={'Take Attendance'}
                visible={isStudentView}
                onHide={() => setIsStudentView(false)}
                footer={renderFooter()}
                draggable={false}
                dismissableMask
            >
                <div className="p-ai-center">
                    <DataTable
                        value={attendanceList}
                        paginator
                        rows={rows}
                        first={first}
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        rowsPerPageOptions={[5, 10, 20]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        onPage={onPageChange}
                        className='p-mt-3'
                    >
                        <Column
                            field="RollNo"
                            header="Roll No"
                        />
                        <Column
                            field="Classname"
                            header="Class No"
                        />
                        <Column
                            field="StudentName"
                            header="Student Name"
                        />
                        <Column
                            field="Attendance"
                            header="Attendance"
                            body={attendanceBodyTemplate}
                        />
                    </DataTable>
                </div>
            </Dialog>
        </div>
    );
};

export default TakeAttendance;
