import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddStopMasterDialog from './AddUpdateStopMaster';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { apiBase } from '../../../utils/config';

const StopMaster = () => {
    const [stopList, setStopList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [stopId, setStopId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        StopName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ColorCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetStopList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const GetStopList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getStopList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setStopList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-0" onClick={() => {
                        setStopId(rows._id)
                        setMasterData(rows);
                        setIsEdit(true)
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2"
                        onClick={(e) => {
                            setIsDeleteConfirmation(true);
                            setDeleteId(rows._id);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    const DeleteStop = async () => {
        try {
            let data = {
                id: deleteId
            }
            await api.post(APIConstant.path.deleteStopDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Stop deleted successfully' });
                    setDeleteId('');
                    GetStopList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                Loader.hide();
                setDeleteId('');
                handleClose();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onCancel = () => {
        setVisible(false);
        setStopId('');
        setIsEdit(false);
        setMasterData([]);
        GetStopList(securityUserID);
    }

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => DeleteStop()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-map-marker"></i>
                    <h2>Stop Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={stopList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['StopName', 'ColorCode']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="StopName"
                    header="Stops"
                />
                <Column
                    field="Longitude"
                    header="Longitude"
                />

                <Column
                    field="Latitude"
                    header="Latitude"
                />
                 {/* <Column
                    field="Route"
                    header="Route"
                /> */}

                <Column body={actionBodyTemplate} header="Action" />
            </DataTable>

            <Dialog
                header={stopId ? "Edit Stop Master" : "Add Stop Master"}
                className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}

                position="center"
            >
                <AddStopMasterDialog masterData={masterData} isEdit={isEdit} ID={stopId} onCancel={onCancel} />
            </Dialog>

            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default StopMaster;
