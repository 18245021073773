import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import AddGradeMasterDialog from './AddUpdateGradeMaster';
import Toast from '../../Shared/Toast/Toast';
import DeleteConfirmation from '../../Dialogs/DeleteConfirmation';
import { InputSwitch } from 'primereact/inputswitch';

const GradeMaster = () => {
    const [gradeList, setGradeList] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [gradeId, setGradeId] = useState('');
    const [visible, setVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        GradeTemplateName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ColorCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [isStatusConfirmation, setIsStatusConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetGradeList(loginData?._id);
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    const toggleActiveStatus = (id, status) => {
        setDeleteId(id);
        setUpdatedStatus(status === true ? 'Active' : 'Inactive');
        setIsStatusConfirmation(true);
    };

    const GetGradeList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getGradeList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setGradeList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const actionBodyTemplate = (rows) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center'>
                    <Button icon="pi pi-pencil" className="e-sction-btn p-m-0" onClick={() => {
                        setGradeId(rows._id)
                        setMasterData(rows);
                        setIsEdit(true)
                        setVisible(true)
                    }} />
                    <Button icon="pi pi-trash" className="e-sction-btn p-m-0 p-m-2"
                        onClick={(e) => {
                            setIsDeleteConfirmation(true);
                            setDeleteId(rows._id);
                        }}
                    />
                    <InputSwitch
                        checked={rows?.Status === 'Active' ? true : false}
                        onChange={(e) => {
                            toggleActiveStatus(rows._id, e.value);
                        }}
                        className="p-ml-2"
                    />
                </div>
            </React.Fragment>
        );
    };

    const UpdateStatus = async () => {
        try {
            let data = {
                id: deleteId,
                Status: updatedStatus
            }
            await api.post(APIConstant.path.updateGradeDetailStatus, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Deleted successfully' });
                    setDeleteId('');
                    setUpdatedStatus('');
                    GetGradeList(securityUserID);
                }
                handleCloseStatus();
            }).catch(err => {
                Loader.hide();
                setDeleteId('');
                setUpdatedStatus('');
                handleCloseStatus();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const DeleteGrade = async () => {
        try {
            let data = {
                id: deleteId
            }
            await api.post(APIConstant.path.deleteGradeDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Loader.hide();
                    Toast.success({ message: 'Grade deleted successfully' });
                    setDeleteId('');
                    setUpdatedStatus('');
                    GetGradeList(securityUserID);
                }
                handleClose();
            }).catch(err => {
                handleClose();
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const onCancel = () => {
        setVisible(false);
        setGradeId('');
        setIsEdit(false);
        setMasterData([]);
        GetGradeList(securityUserID);
    }

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={(e) => DeleteGrade()}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const renderStatusFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleCloseStatus}
            />
            <Button
                label="Update"
                className="primary-btn"
                onClick={(e) => UpdateStatus()}
            />
        </div>
    );

    const handleCloseStatus = () => {
        setIsStatusConfirmation(false)
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    return (
        <div className='wayment-owner-dashboard e-weighment-dashoard'>
            <div className="heading-area p-d-flex p-ai-center p-jc-between">
                <div className="p-d-flex p-ai-baseline">
                    <i className="pi pi-home"></i>
                    <h2>Grade Master</h2>
                </div>
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div className="searchbar-area e-searchbar-area">
                        <i className="pi pi-search" />
                        <InputText
                            // 
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Global Search"
                        />
                    </div>
                    <Button icon="pi pi-plus" className="e-btn" onClick={() => setVisible(true)} />
                </div>
            </div>
            <hr className='p-m-0' />
            <DataTable
                value={gradeList}
                paginator
                rows={rows}
                first={first}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['GradeTemplateName']}
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 20]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                onPage={onPageChange}
                className='p-mt-3'
            >
                <Column field="id" body={actionBodyTemplateSrNo} header="Sr No." />
                <Column
                    field="GradeTemplateName"
                    header="Grade Template Name"
                />
                {/* <Column
                    field="ColorCode"
                    header="Colour Code"
                /> */}

                <Column body={actionBodyTemplate} header="Action" />
            </DataTable>

            <Dialog
                header={gradeId ? "Edit Grade Master" : "Add Grade Master"}
                // className='dialog-popup'
                visible={visible}
                onHide={() => onCancel()}
                draggable={false}
                position="center"
            >
                <AddGradeMasterDialog masterData={masterData} isEdit={isEdit} ID={gradeId} onCancel={onCancel} />
            </Dialog>

            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
            <Dialog
                visible={isStatusConfirmation}
                onHide={handleCloseStatus}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Status Update</span>
                    </div>
                }
                footer={renderStatusFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <div>
                    <p>
                        Are you sure you want to update status?
                    </p>
                </div>
            </Dialog>
        </div>
    );
};

export default GradeMaster;
