import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import Loader from "../../Shared/Loader/Loader";
import APIConstant from "../../../utils/pathConstants";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';

const AddEditCoScholasticTemplate = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [titleName, setTitleName] = useState('');
    const [visible, setVisible] = useState(false);
    const [titleNameErr, setTitleNameErr] = useState(false);
    const [titleNameErrText, setTitleNameErrText] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [resultTemplateValues, setResultTemplateValues] = useState([]);
    const [classList, setClassList] = useState([]);
    const [className, setClassName] = useState([]);
    const [coScholasticList, setCoScholasticList] = useState([]);
    const [coScholasticName, setCoScholasticName] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [gradeName, setGradeName] = useState([]);
     const [coScholasticType, setCoScholasticType] = useState('Activity');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        GetClassList(loginData?._id);
        GetCoScholasticList(loginData?._id);
        GetGradeList(loginData?._id);
        if (props?.configId) {
            setTitleName(props?.masterData?.TemplateName);
            setClassName(props?.masterData?.ClassId ? props?.masterData?.ClassId.split(',') : []);
            setCoScholasticType(props?.masterData?.Type);
            setGradeName(props?.masterData?.GradeName ? props?.masterData?.GradeName.split(',') : []);
            setCoScholasticName(props?.masterData?.CoScholasticName ? props?.masterData?.CoScholasticName.split(',') : []);
        }
    }, []);

    const GetClassList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getClassList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setClassList(res.data.map((x) => ({
                        ...x,
                        value: x?._id,
                        label: `${x?.ClassName}-${x?.SectionFullName.charAt(0)}-${x?.Medium}-${x?.Board}`
                    })));

                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetGradeList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getGradeList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setGradeList(res?.data?.map((x) => ({
                        ...x,
                        value: x?._id,
                        label: x?.GradeTemplateName
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetCoScholasticList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getCoScholasticList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setCoScholasticList(res?.data?.map((x) => ({
                        ...x,
                        value: x?._id,
                        label: x?.CoScholasticName
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(titleName)) {
            formIsValid = false;
            setTitleNameErr(true);
            setTitleNameErrText("Title Name is required");
        } else {
            setTitleNameErr(false);
            setTitleNameErrText("");
        }
        return formIsValid;
    };

    const onCancel = () => {
        props.onCancel();
        setTitleName('');
    }

    const AddUpdateTemplate = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props?.configId,
                    TemplateName: titleName,
                    ClassId: className ? className.join(',') : '',
                    GradeName: gradeName ? gradeName.join(',') : '',
                    CoScholasticName: coScholasticName ? coScholasticName.join(',') : '',
                    Type: coScholasticType,
                    CreatedBy: securityUserID,
                };
                await api
                    .post(APIConstant.path.AddUpdateCoScholasticTemplate, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Toast.error({ message: err });
                Loader.hide();
                onCancel();
            }
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-jc-between">
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="titleName">Template Name</label>
                    <FloatLabel>
                        <InputText
                            className='p-w-100'
                            id="titleName"
                            value={titleName}
                            onChange={(e) => setTitleName(e.target.value)}
                        />
                    </FloatLabel>
                    <span className="error-msg" style={{ color: 'red' }}>
                        {titleNameErr ? titleNameErrText : null}
                    </span>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="className">Class</label>
                        <MultiSelect
                            id="className"
                            value={className}
                            options={classList}
                            onChange={(e) => setClassName(e.value)}
                            placeholder="Select Class"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <RadioButton
                            inputId="Activity"
                            name="coScholasticType"
                            value="Activity"
                            checked={coScholasticType === 'Activity'}
                            onChange={(e) => setCoScholasticType(e.value)}
                        />
                        <label htmlFor="Activity"> Activity</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <RadioButton
                            inputId="Elements"
                            name="coScholasticType"
                            value="Elements"
                            checked={coScholasticType === 'Elements'}
                            onChange={(e) => setCoScholasticType(e.value)}
                        />
                        <label htmlFor="Elements"> Elements</label>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="coScholasticName">Co-Scholastic</label>
                        <MultiSelect
                            id="coScholasticName"
                            value={coScholasticName}
                            options={coScholasticList}
                            onChange={(e) => setCoScholasticName(e.value)}
                            placeholder="Select Co-Scholastic"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="">
                        <label htmlFor="GradeName">Grade</label>
                        <MultiSelect
                            id="GradeName"
                            value={gradeName}
                            options={gradeList}
                            onChange={(e) => setGradeName(e.value)}
                            placeholder="Select Grade"
                            display="chip"
                            className='p-w-100'
                            filter
                        />
                    </div>
                </div>
            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='p-m-0 primary-btn1 p-m-1' label="Cancel" onClick={(e) => onCancel()} />
                <Button className='p-m-0 primary-btn' label="Save" onClick={(e) => AddUpdateTemplate()} />
            </div>
        </div >
    );
};

export default AddEditCoScholasticTemplate;
