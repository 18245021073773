import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Loader from "../../Shared/Loader/Loader";
import api from "../../../utils/apiClient";
import CommonConfig from '../../../utils/constant';
import Toast from '../../Shared/Toast/Toast';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { FloatLabel } from 'primereact/floatlabel';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "leaflet/dist/leaflet.css";
import APIConstant from "../../../utils/pathConstants";

const containerStyle = {
    width: "100%",
    height: "200px",
};

const center = {
    lat: 23.219537206,
    lng: 70.319537206,
};

const AddUpdateStopMaster = (props) => {
    const [securityUserID, setSecurityUserID] = useState("");
    const [stopName, setStopName] = useState("");
    const [stopNameErr, setStopNameErr] = useState(false);
    const [stopNameErrText, setStopNameErrText] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [latitudeErr, setLatitudeErr] = useState(false);
    const [latitudeErrText, setLatitudeErrText] = useState("");
    const [longitudeErr, setLongitudeErr] = useState(false);
    const [longitudeErrText, setLongitudeErrText] = useState("");
    const [route, setRoute] = useState("");
    const [selectedCoordinates, setSelectedCoordinates] = useState(null);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?._id);
        if (props.ID) {
            setStopName(props?.masterData?.StopName);
            setLatitude(props?.masterData?.Latitude);
            setLongitude(props?.masterData?.Longitude);
            setSelectedCoordinates([props?.masterData?.Latitude, props?.masterData?.Longitude]);
        }
    }, []);

    const handleMapClick = (event) => {
        setLatitude(event.latLng.lat());
        setLongitude(event.latLng.lng());
        setSelectedCoordinates([event.latLng.lat(), event.latLng.lng()]);
    };

    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s!@#$%^&*.]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'StopName') {
            setStopName(alphanumericValue);
        } else if (type === 'Latitude') {
            setLatitude(alphanumericValue);
        } else if (type === 'Longitude') {
            setLongitude(alphanumericValue);
        }
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(stopName)) {
            formIsValid = false;
            setStopNameErr(true);
            setStopNameErrText("Stop Name is required");
        } else {
            setStopNameErr(false);
            setStopNameErrText("");
        }
        if (CommonConfig.isEmpty(latitude)) {
            formIsValid = false;
            setLatitudeErr(true);
            setLatitudeErrText("Latitude is required");
        } else {
            setLatitudeErr(false);
            setLatitudeErrText("");
        }
        if (CommonConfig.isEmpty(longitude)) {
            formIsValid = false;
            setLongitudeErr(true);
            setLongitudeErrText("Longitude is required");
        } else {
            setLongitudeErr(false);
            setLongitudeErrText("");
        }
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        if (validate(e)) {
            try {
                Loader.show();
                let data = {
                    id: props.ID,
                    StopName: stopName,
                    Latitude: latitude,
                    Longitude: longitude,
                    RouteName: route,
                    CreatedBy: securityUserID,
                };

                await api
                    .post(APIConstant.path.AddUpdateStopMaster, data)
                    .then((response) => {
                        let res = response;
                        if (res.success) {
                            Toast.success({ message: res.message });
                            Loader.hide();
                            onCancel();
                        } else {
                            Toast.error({ message: res.message });
                            Loader.hide();
                            onCancel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        Loader.hide();
                        onCancel();
                    });
            } catch (err) {
                console.log(err);
                Loader.hide();
                onCancel();
            }
        }
    };

    const onCancel = () => {
        props.onCancel();
        setStopName('');
        setLatitude('');
        setLongitude();
        setRoute('');
    }

    return (
        <div>
            <div className='p-grid'>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="stopName">Stop</label>
                    <div className="custom-inputs">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="stop"
                                type="text"
                                maxLength={20}
                                placeholder="Stop"
                                value={stopName}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'StopName')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {stopNameErr ? stopNameErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <div style={{ height: "50px", width: "100%", border: "1px solid #ccc", borderRadius: "8px" }}>
                        <LoadScript googleMapsApiKey={APIConstant.path.GoogleMapApiKey}>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onClick={handleMapClick}
                            >
                                {selectedCoordinates && (
                                    <Marker position={{ lat: selectedCoordinates[0], lng: selectedCoordinates[1] }} />
                                )}
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="latitude">Latitude</label>
                    <div className="custom-inputs">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="latitude"
                                maxLength={20}
                                placeholder="Latitude"
                                value={latitude}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'Latitude')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {latitudeErr ? latitudeErrText : null}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                    <label htmlFor="longitude">Longitude</label>
                    <div className="custom-inputs">
                        <FloatLabel>
                            <InputText
                                className="p-mt-2 p-pl-2 p-w-100"
                                id="longitude"
                                maxLength={20}
                                placeholder="Longitude"
                                value={longitude}
                                autoFocus={true}
                                onChange={(e) => handleChange(e, 'Longitude')}
                            />
                        </FloatLabel>
                        <span className="error-msg" style={{ color: 'red' }}>
                            {longitudeErr ? longitudeErrText : null}
                        </span>
                    </div>
                </div>

            </div>
            <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
                <Button className='primary-btn1' onClick={(e) => onCancel()}> cancel </Button>
                <Button className='primary-btn' label="Submit" severity="secondary" raised onClick={(e) => handleSubmit()} />
            </div>
        </div>
    );
};

export default AddUpdateStopMaster;
