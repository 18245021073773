import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import Loader from "../Shared/Loader/Loader";
import { Dropdown } from 'primereact/dropdown';
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import CommonConfig from '../../utils/constant';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import Toast from '../Shared/Toast/Toast';
import moment from 'moment';
import { FileUpload } from 'primereact/fileupload';
import { apiBase } from '../../utils/config';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../Dialogs/DeleteConfirmation';

const SchoolProfile = () => {
    const navigate = useNavigate();
    const [boardList, setBoardList] = useState([]);
    const [personalDetailId, setPersonalDetailId] = useState('');
    const [schoolDetailid, setSchoolDetailId] = useState('');
    const [profilePath, setProfilePath] = useState(null);
    const [board, setBoard] = useState([]);
    const [medium, setMedium] = useState([]);
    const [userName, setUserName] = useState('');
    const [schoolIndexNo, setSchoolIndexNo] = useState('');
    const [slogan, setSlogan] = useState('');
    const [schoolEstabalishedDate, setSchoolEstabalishedDate] = useState('');
    const [affiliationNo, setAffiliationNo] = useState('');
    const [affiliationFrom, setAffiliationFrom] = useState('');
    const [affiliationTo, setAffiliationTo] = useState('');
    const [panNo, setPanNo] = useState('');
    const [gstNo, setGSTNo] = useState('');
    const [securityUserID, setSecurityUserID] = useState("");
    const mediumList = [
        { label: 'English', value: 'English' },
        { label: 'Hindi', value: 'Hindi' }
    ];
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [emailErrText, setEmailErrText] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [planName, setPlanName] = useState('');
    const [planFrom, setPlanFrom] = useState('');
    const [planTo, setPlanTo] = useState('');
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
    const [previewProfilePath, setPreviewProfilePath] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                Loader.show();
                const loginData = CommonConfig.loginData();
                setSecurityUserID(loginData?._id);
                await GetBoardList(loginData?._id);
                await GetUserDetail(loginData?._id);
                await getPersonDetail(loginData?._id);
                await getSchoolDetail(loginData?._id);
                Loader.hide();
            } catch (error) {
                Loader.hide();
                console.error('Error in useEffect:', error);
            } finally {
                Loader.hide();
            }
        }
        fetchData();
    }, []);

    const GetBoardList = async (id) => {
        try {
            let data = {
                UserID: id,
            }
            await api.post(APIConstant.path.getBoardList, data).then(async response => {
                let res = response;
                if (res.success) {
                    setBoardList(res.data.map(x => ({
                        ...x,
                        label: x?.BoardName,
                        value: x?._id
                    })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const GetUserDetail = async (userid) => {
        try {
            let data = {
                id: userid,
            }
            await api.post(APIConstant.path.getUserById, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUserName(res?.data?.Name);
                    setMobileNumber(res?.data?.Phone);
                    setEmailAddress(res?.data?.Email);
                    setPlanName(res?.data?.PlanId);
                    setPlanFrom(res?.data?.PlanStartDate ? new Date(res?.data?.PlanStartDate) : '');
                    setPlanTo(res?.data?.PlanEndDate ? new Date(res?.data?.PlanEndDate) : '');
                    setProfilePath(res?.data?.ProfilePhotoUrl ? `${apiBase}/UserProfilePic/${res?.data?.ProfilePhotoUrl}` : null)
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const getPersonDetail = async (ID) => {
        try {
            let data = {
                id: ID,
            };
            await api
                .post(APIConstant.path.GetPersonalDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setPersonalDetailId(res?.data?._id);
                        setCurrentAddress(res?.data?.CurrentAddress);
                        setPinCode(res?.data?.PinCode);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const getSchoolDetail = async (ID) => {
        try {
            let data = {
                id: ID,
            };
            await api
                .post(APIConstant.path.GetSchoolDetailById, data)
                .then(async (response) => {
                    let res = await response;
                    if (res.success) {
                        Loader.hide();
                        setSchoolDetailId(res?.data[0]?._id);
                        setSchoolIndexNo(res?.data[0]?.IndexNo);
                        setSlogan(res?.data[0]?.Slogan);
                        setMedium(res?.data[0]?.Medium?.split(',') || []);
                        setBoard(res?.data[0]?.Board?.split(',') || []);
                        setSchoolEstabalishedDate(res?.data[0]?.SchoolEstablished ? new Date(res?.data[0]?.SchoolEstablished) : '');
                        setAffiliationFrom(res?.data[0]?.AffiliationFrom ? new Date(res?.data[0]?.AffiliationFrom) : '');
                        setAffiliationTo(res?.data[0]?.AffiliationTo ? new Date(res?.data[0]?.AffiliationTo) : '');
                        setAffiliationNo(res?.data[0]?.AffiliationNo);
                        setPanNo(res?.data[0]?.PANNo);
                        setGSTNo(res?.data[0].GSTNo);
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSave = async () => {
        await AddEditPersonalDetail();
        await AddEditSchoolDetail();
        await AddEditUserDetail();
        onReferesh();
        handleBack();
    }

    const onReferesh = () => {
        GetUserDetail(securityUserID);
        getPersonDetail(securityUserID);
        getSchoolDetail(securityUserID);
    }

    const AddEditPersonalDetail = async () => {
        try {
            let data = {
                id: personalDetailId,
                UserId: securityUserID,
                PinCode: pinCode,
                CurrentAddress: currentAddress,
                CreatedBy: securityUserID,
            };
            Loader.show();
            await api.post(APIConstant.path.AddPersonalDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    Toast.success({ message: 'Details Updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const AddEditSchoolDetail = async () => {
        try {
            let data = {
                id: schoolDetailid,
                UserId: securityUserID,
                PANNo: panNo,
                SchoolEstablished: schoolEstabalishedDate ? moment(schoolEstabalishedDate).format("YYYY-MM-DD") : '',
                AffiliationFrom: affiliationFrom ? moment(affiliationFrom).format("YYYY-MM-DD") : '',
                AffiliationTo: affiliationTo ? moment(affiliationTo).format("YYYY-MM-DD") : '',
                AffiliationNo: affiliationNo,
                Board: board.length > 1 ? board.join(',') : board[0],
                Medium: medium.length > 1 ? medium.join(',') : medium[0],
                Slogan: slogan,
                GSTNo: gstNo,
                IndexNo: schoolIndexNo,
                CreatedBy: securityUserID
            };
            Loader.show();
            await api.post(APIConstant.path.AddSchoolDetail, data).then(async response => {
                let res = response;
                if (res.success) {
                    // Toast.success({ message: 'School details updated' });
                    Loader.hide();
                } else {
                    Toast.error({ message: res.message });
                    Loader.hide();
                }
            })
                .catch(() => {
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Loader.hide();
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.files[0];
        if (selectedFile) {
            const validFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
            if (validFormats.includes(selectedFile.type)) {
                const img = new Image();
                img.onload = () => {
                    // if (img.width === img.height) {
                        setProfilePath(selectedFile);
                        const fileURL = URL.createObjectURL(selectedFile);
                        setPreviewProfilePath(fileURL);
                    // } else {
                    //     Toast.error({ message: 'The image must have a 1:1 aspect ratio.' });
                    // }
                };
                img.onerror = () => {
                    Toast.error({ message: 'Error loading the image.' });
                };
                
                const reader = new FileReader();
                reader.onload = (event) => {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            } else {
                Toast.error({ message: 'Invalid file format. Please select a JPEG, SVG or PNG image.' });
            }
        }
    };
    
    const handleChange = (e, type) => {
        const value = e.target.value;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        const numericValue = value.replace(/[^0-9]/g, '');
        if (type === 'PAN') {
            setPanNo(alphanumericValue.toUpperCase().trim());
        } else if (type === 'GST') {
            setGSTNo(alphanumericValue.toUpperCase());
        } else if (type === 'AffiliationNo') {
            setAffiliationNo(numericValue);
        } else if (type === 'IndexNo') {
            setSchoolIndexNo(numericValue);
        } else if (type === 'MobileNo') {
            setMobileNumber(numericValue);
        } else if (type === 'PinCode') {
            setPinCode(numericValue);
        }

    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        const maxLength = 50;
        if (value.length <= maxLength) {
            setEmailAddress(value);
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
            setEmailErr(true);
            setEmailErrText('Please enter a valid email address.');
            // Toast.error({ message: 'Please enter a valid email address.' });
        } else {
            setEmailErr(false);
            setEmailErrText('');
        }
    };

    const AddEditUserDetail = async () => {
        try {
            const formData = new FormData();
            formData.append("id", securityUserID);
            formData.append("Name", userName);
            formData.append("Email", emailAddress);
            formData.append("Phone", mobileNumber);
            formData.append("CreatedBy", securityUserID);
            if (profilePath && profilePath.type) {
                formData.append("userProfilePic", profilePath);
            } else if(CommonConfig.isEmpty(profilePath)){
                formData.append("userProfilePic", '');
            }
            Loader.show();
            await api.post(APIConstant.path.AddUpdateUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(async (response) => {
                    let res = response;
                    if (res.success) {
                        // Toast.success({ message: 'User details updated successfully' });
                    } else {
                        Toast.error({ message: res.message });
                    }
                    Loader.hide();
                })
                .catch((err) => {
                    console.error(err);
                    // Toast.error({ message: 'Failed to update user details' });
                    Loader.hide();
                });
        } catch (err) {
            console.log(err);
            Toast.error({ message: 'An error occurred' });
            Loader.hide();
        }
    };

    const renderFooter = () => (
        <div className="dialog-footer1 p-d-flex p-ai-center p-jc-end">
            <Button
                label="Cancel"
                className="primary-btn1"
                onClick={handleClose}
            />
            <Button
                label="Delete"
                className="primary-btn"
                onClick={() => {
                    setProfilePath('')
                    setPreviewProfilePath('');
                    handleClose()}}
            />
        </div>
    );

    const handleClose = () => {
        setIsDeleteConfirmation(false)
    };

    const handleBack = () => {
        const loginData = CommonConfig.loginData();
        CommonConfig.getUserMenu(loginData, navigate);
    }

    return (
        <div className="p-4">
            <h2 className="p-mb-4">User Profile</h2>
            <TabView>
                <TabPanel header="Basic details">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <div className="p-fluid">
                                <div className="p-field">
                                    <label>School Name</label>
                                    <InputText value={userName} maxLength={40} onChange={(e) => setUserName(e.target.value)} />
                                </div>
                                <div className="p-field">
                                    <label>School Index No.</label>
                                    <InputText value={schoolIndexNo} maxLength={20} onChange={(e) => handleChange(e, 'IndexNo')} />
                                </div>
                                <div className="p-field">
                                    <label>Slogan</label>
                                    <InputText id='slogan' value={slogan} maxLength={20} onChange={(e) => setSlogan(e.target.value)} />
                                </div>
                                <div className="p-field">
                                    <label>Mediums</label>
                                    <MultiSelect id="medium" value={medium} options={mediumList}
                                        onChange={(e) => setMedium(e.value)} placeholder="Select Medium" filter display='chip' />
                                </div>
                                <div className="p-field">
                                    <label>School Established</label>
                                    <Calendar id="schoolEstabalishedDate" value={schoolEstabalishedDate} onChange={(e) => setSchoolEstabalishedDate(e.value)} showIcon dateFormat='dd/mm/yy' />
                                </div>
                                <div className="p-field">
                                    <label>Board</label>
                                    <MultiSelect id="board" value={board} options={boardList}
                                        onChange={(e) => setBoard(e.value)} placeholder="Select Board" filter display='chip' />
                                </div>
                                <div className="p-field">
                                    <label>Affiliation Number</label>
                                    <InputText maxLength={10} value={affiliationNo} onChange={(e) => handleChange(e, 'AffiliationNo')} />
                                </div>
                                <div className="p-field">
                                    <label>Affiliation From</label>
                                    <Calendar id="affiliationFrom" value={affiliationFrom} onChange={(e) => setAffiliationFrom(e.value)} showIcon dateFormat='dd/mm/yy' />
                                </div>
                                <div className="p-field">
                                    <label>Affiliation To</label>
                                    <Calendar id="affiliationTo" value={affiliationTo} onChange={(e) => setAffiliationTo(e.value)} showIcon dateFormat='dd/mm/yy' />
                                </div>
                                <div className="p-field">
                                    <label>PAN</label>
                                    <InputText maxLength={10} value={panNo} onChange={(e) => handleChange(e, 'PAN')} />
                                </div>
                                <div className="p-field">
                                    <label>GST No. (Optional)</label>
                                    <InputText maxLength={15} value={gstNo} onChange={(e) => handleChange(e, 'GST')} />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-text-center" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <img
                                    src={!CommonConfig.isEmpty(previewProfilePath) ? previewProfilePath : !CommonConfig.isEmpty(profilePath) ? profilePath : "https://via.placeholder.com/150"}
                                    alt=""
                                    style={{ width: "150px", height: "150px", borderRadius: "8px" }}
                                />

                                <FileUpload
                                    id="profilePath"
                                    mode="basic"
                                    name="profilePath"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    chooseLabel="Profile"
                                    auto={false}
                                    onSelect={(e) => handleFileSelect(e)}
                                />

                                {!CommonConfig.isEmpty(profilePath) && (
                                    <div
                                        className="icon-overlay"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setIsDeleteConfirmation(true)}
                                    >
                                        <i className="pi pi-trash" style={{ fontSize: "18px", color: "red" }}></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Contact details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="fullName">Email Address</label>
                                <InputText id="fullName" value={emailAddress} onChange={(e) => handleEmailChange(e)} />
                                <span className="p-error block" style={{ color: 'red' }}>
                                    {emailErr ? emailErrText : null}
                                </span>
                            </div>
                            <div className="p-field">
                                <label htmlFor="contactNo">Contact Number</label>
                                <InputText id="contactNo" maxLength={10} value={mobileNumber} onChange={(e) => handleChange(e, 'MobileNo')} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="pinCode">Pin Code</label>
                                <InputText id="pinCode" maxLength={6} value={pinCode} onChange={(e) => handleChange(e, 'PinCode')} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="currentAddress">Current Address</label>
                                <InputTextarea id="currentAddress" value={currentAddress} type='textArea' rows={5} cols={30} onChange={(e) => setCurrentAddress(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Plan details">
                    <div className="form-section">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="planName">Plan Name</label>
                                <InputText id="planName" value={planName} disabled />
                            </div>
                            <div className="p-field">
                                <label htmlFor="planDuration">Plan Duration</label>
                                <div className="p-inputgroup">
                                    <label htmlFor="planFrom">From</label>
                                    <Calendar id="planFrom" value={planFrom} showIcon disabled dateFormat='dd/mm/yy' />
                                    <label htmlFor="planTo">To</label>
                                    <Calendar id="planTo" value={planTo} showIcon disabled dateFormat='dd/mm/yy' />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div className="form-actions">
                <Button label="Back to Home" icon="pi pi-arrow-left" className="p-button-secondary p-mt-4" onClick={() => handleBack()} />
                <Button label="Save" className="p-button-primary p-mt-4" onClick={() => handleSave()} />
            </div>
            <Dialog
                visible={isDeleteConfirmation}
                onHide={handleClose}
                header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                        <span>Delete Confirmation</span>
                    </div>
                }
                footer={renderFooter()}
                draggable={false}
                dismissableMask
                style={{ width: '450px' }}
            >
                <DeleteConfirmation />
            </Dialog>
        </div>
    );
};

export default SchoolProfile;
